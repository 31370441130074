<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">商品管理</h3>
    </div>
    <div class="box">
      <div class="ctn">
        <div class="btnbox">
          <span class="shou addGoods" @click="addgods">
            <i class="el-icon-folder-add"></i> 添加商品
          </span>
        </div>
        <div class="tiaojian">
          <div class="left">
            <div class="checkboxBox">
              <span style="font-size: 14px; margin-right: 10px; color: #606266"
                >是否上架:</span
              >
              <el-radio v-model="form.status" :label="null">全部</el-radio>
              <el-radio v-model="form.status" :label="1">上架</el-radio>
              <el-radio v-model="form.status" :label="0">下架</el-radio>
            </div>
            <strong>商品名称: </strong>
            <el-input
              v-model="form.name"
              placeholder="请输入商品名称以筛选"
            ></el-input>
            <strong>是否符合送九宫格抽奖次数商品: </strong>
            <el-select v-model="form.goods_lottery" placeholder="请选择">
              <el-option
                v-for="item in goods_lotteryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <span class="shaixuan shou" @click="filterList">筛选</span>
        </div>
        <el-button type="success" size="small" plain @click="exportShop"
          >批量导出</el-button
        >
        <!-- <a href="javascript:;" class="export">批量导出</a> -->
      </div>
    </div>
    <el-table
      :data="tableData"
      stripe
      border
      :header-row-style="{ height: '50px' }"
      style="width: 100%"
    >
      <el-table-column prop="id" label="商品ID" width="80"> </el-table-column>
      <el-table-column prop="name" label="商品名称" width="200">
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="100"> </el-table-column>
        
      <el-table-column prop="goods_no" label="商品编码" width="180"> </el-table-column>
      <el-table-column prop="sort" label="是否是抽奖商品" width="160">
      <template slot-scope="scope">
        {{scope.row.goods_lottery == 1 ? '是' : '否'}}
      </template>
      </el-table-column>
      <el-table-column label="商品图片" width="80">
        <div class="listimg" slot-scope="scope">
          <img :src="imgurl + scope.row.cover_pic" alt="" />
        </div>
      </el-table-column>
      <el-table-column prop="price" label="售价" width="120"> </el-table-column>
      <el-table-column prop="cost_price" label="会员价" width="120">
      </el-table-column>
      <el-table-column prop="goods_num" label="库存数" width="90">
      </el-table-column>
      <el-table-column label="分佣" width="90">
        <div class="fyslot" slot-scope="scope">
          <el-tag v-if="scope.row.is_agent_share == 0" size="mini" type="info"
            >不分佣</el-tag
          >
          <el-tag v-else size="mini" type="success">分佣</el-tag>
        </div>
      </el-table-column>
      <el-table-column label="状态" width="140">
        <div class="Dflex" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.status == 1" type="success"
            >已上架</el-tag
          >
          <el-tag size="mini" v-else effect="dark" type="info">已下架</el-tag>
          <div
            class="setState shou"
            @click="setState(scope.row.id, scope.row.status)"
          >
            修改
          </div>
        </div>
      </el-table-column>
      <el-table-column label="操作">
        <div class="caozuo" slot-scope="scope">
          <div
            class="btnBJ shou"
            style="margin-right: 8px"
            @click="compile(scope.row.id)"
          >
            编辑
          </div>
          <div
            class="btnDel shou"
            style="margin-right: 8px"
            @click="del(scope.row.id)"
          >
            删除
          </div>
          <el-button
            size="mini"
            @click="set_comment(scope.row.id, scope.row.name)"
            type="primary"
            round
            >评价</el-button
          >
        </div>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="crtChg"
      :current-page="form.page"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 弹窗 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="numberValidateForm"
        ref="numberValidateForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="评论内容">
          <el-input
            placeholder="请输入评论内容"
            type="textarea"
            v-model="numberValidateForm.content"
          ></el-input>
        </el-form-item>
        <el-form-item class="el-form-item" label="图片或视频">
          <div class="imagelist">
            <template v-if="pic_list.length >= 1">
              <div v-for="(item, index) in pic_list" :key="index" class="image">
                <img
                  v-if="item.split('.')[item.split('.').length - 1] != 'mp4'"
                  :src="item"
                  alt=""
                />
                <video style="height: 100%" v-else :src="item" alt="" />
                <span class="shou" @click="delImgList(index)">×</span>
              </div>
            </template>
            <up-img
              v-if="pic_list.length < 5"
              @addimg="upImgList"
              :type="'shop'"
            ></up-img>
          </div>
        </el-form-item>
        <el-form-item>
          <!-- <span slot="footer" class="dialog-footer"> -->
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="summitComment">确 定</el-button>
          <!-- </span> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportOK" >确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getgoodslist,
  delgoods,
  setGoodsStatus,
  addFalseComment,
} from "@/api/api.js";
import UpImg from "@/components/upImg.vue";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import { filenameFormat } from "@/utils/index";
export default {
  components: { UpImg },
  name: "goodsList",
  data() {
    return {
      numberValidateForm: {
        content: "",
        id: "",
      },
      pic_list: [],
      dialogVisible: false,
      title: "",
      form: {
        name: "",
        status: null,
        goods_lottery : 0, //是否是抽奖商品
        page: 1, //  页数
      },
      goods_lotteryList: [
        {name: '全部', id: 0},
        {name: '是', id: 1},
        {name: '不是', id: 2}
      ],
      total: 1,
      tableData: [],
      imgurl: "",
      exportShow: false,
      exportType: 1,
    };
  },
  methods: {
    filterList() {
      this.form.page = 1;
      this.goodsList();
    },

    async goodsList() {
      if (!this.form.export) {
        const { data } = await getgoodslist(this.form);
        if (data.code != 200) return this.$message.error(data.data);
        this.tableData = data.data.goods_list.data;
        this.total = data.data.goods_list.total;
        console.log(this.tableData);
      } else {
        let { data, types, request } = await getgoodslist(this.form, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // decodeURI(request.getResponseHeader("content-disposition").split('=')[1]);
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition")
        // );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        // console.log('filename====',filename)
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
        this.form.export = "";
      }
    },

    addgods() {
      this.$router.push("/goods/add");
    },

    compile(id) {
      this.$router.push({ path: "/goods/add", query: { id } });
    },

    del(id) {
      this.$confirm("您真的要删除该商品吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delgoods({ id });
          if (data.code != 200) return this.$message.error(data.data);
          this.tableData.forEach((item) => {
            if (item.id == id) {
              let index = this.tableData.indexOf(item);
              console.log(index);
              this.tableData.splice(index, 1);
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    crtChg(e) {
      this.form.page = e;
      this.goodsList();
    },

    delImgList(index) {
      console.log(index);
      this.pic_list.splice(index, 1);
    },

    upImgList(imgurl) {
      if (imgurl) {
        this.pic_list.push(imgurl);
      } else {
        this.pic_list.push(imgurl);
      }

      console.log(this.pic_list);
    },

    setState(id, status) {
      this.$confirm("请确定是否修改当前状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let newstatus = status == 1 ? 0 : 1;
          const { data } = await setGoodsStatus({ id, type: newstatus });
          if (data.code != 200) return this.$message.error(data.data);
          this.tableData.forEach((item) => {
            if (item.id == id) {
              item.status = newstatus;
            }
          });
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    set_comment(id, name) {
      this.title = name;
      this.dialogVisible = true;
      this.numberValidateForm.id = id;
    },
    // 导出订单
    exportShop() {
      this.exportShow = true;
    },
    // 取消导出
    exportShows() {
      this.exportShow = false;
    },
    // 确定导出
    exportOK() {
      this.exportShow = false;
      this.form.export = "export";
      this.form.export_type = this.exportType;
      this.goodsList();
      delete this.form.export;
      delete this.form.export_type;
    },
    summitComment() {
      let picstr = JSON.stringify(this.pic_list);
      this.numberValidateForm.pic_list = picstr;
      console.log(this.numberValidateForm.pic_list);
      this.$refs["numberValidateForm"].validate(async (valid) => {
        if (valid) {
          const { data } = await addFalseComment(this.numberValidateForm);
          if (data.code != 200) return this.$message.error(data.data);
          this.$message.success(data.data);
          this.dialogVisible = false;
        } else {
          this.$message.warning("请检查填写内容!");
          return false;
        }
      });
    },
  },
  created() {
    this.goodsList();
  },
  mounted() {
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
  },
};
</script>

<style scoped lang='less'>
.box-card {
  flex: 1;
  .box {
    .ctn {
      padding: 10px 20px 0 10px;
      padding-bottom: 30px;
      .btnbox {
        .addGoods {
          background-color: #179bfe;
          color: #fff;
          padding: 6px 10px;
          border-radius: 3px;
        }
      }
      .tiaojian {
        margin: 20px 0;
        padding: 10px;
        display: flex;
        align-items: center;
        background-color: #edeff0;
        position: relative;
        .left {
          strong {
            font-weight: normal;
            font-size: 14px;
            color: #606266;
            padding-top: 10px;
            margin-right: 2px;
          }
          .el-input {
            width: 300px;
            margin-right: 20px;
            /deep/.el-input__inner {
              height: 30px;
            }
          }
          .checkboxBox {
            padding-top: 10px;
            margin: 0 0 20px 0;
          }
        }

        .shaixuan {
          background-color: #18bdff;
          color: aliceblue;
          padding: 3px 10px;
          border-radius: 2px;
          position: absolute;
          bottom: 15px;
          left: 59%;
          &:hover {
            background-color: #1abe9c;
          }
        }
      }
    }
    .el-pagination {
      margin: 10px 0 10px 0;
    }
  }
  .setState {
    font-size: 10px;
    padding: 1px 5px;
    background-color: #009688;
    margin: 0 15px;
    font-size: 12px;
    color: #fff;
    border-radius: 5px;
  }
  .listimg {
    width: 50px;
    img {
      width: 100%;
    }
  }
}
.el-pagination {
  margin-top: 20px;
}
.el-table /deep/td,
.el-table /deep/th {
  padding: 0;
}
.el-form-item {
  .imagelist {
    display: flex;
    flex-wrap: wrap;
  }
  .el-radio {
    margin-left: 30px;
    &:last-child {
      margin-left: 0px;
    }
  }
  .image {
    font-size: 12px;
    width: 100px;
    height: 100px;
    color: #aaa;
    border: 1px solid #eeeeee;
    position: relative;
    top: -10px;
    margin: 10px 10px 0 0;
    img {
      width: 100px;
      height: 100px;
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      height: 20px;
      background-color: #ffd1d1;
      color: #fff3f3;
      z-index: 9;
      &:hover {
        background-color: #ff4544;
        color: #ffffff;
      }
    }
  }
  .upimg {
    border: 1px solid #777;
    font-size: 20px;
    padding: 1px 12px;
    margin: 1px 0 0 20px;
    color: #777;
    &:last-child {
      margin: 10px;
    }
  }
  position: relative;
  .dingwei {
    position: absolute;
    bottom: -26px;
    font-size: 12px;
    color: #777;
  }
  .el-input {
    .append {
      padding: 0;
      display: flex;
      justify-content: space-between;
      i {
        display: block;
        &:nth-child(2) {
          background-color: pink;
        }
      }
    }
    width: 500px;
    /deep/.el-input__inner {
      height: 34px;
      color: #333;
    }
  }
  .el-dropdown {
    span {
      border: 1px solid #888;
      padding: 6px 10px;
      margin-left: 30px;
    }
  }
  .image {
    width: 100px;
    height: 100px;
  }
}
</style>